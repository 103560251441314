import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ResponsiveImage from "../common/responsiveImage"
import ResponsiveImageText from "../common/responsiveImageText"
import Micki from "../images/micki"
import config from "../../../data/SiteConfig"

export default function AboutMeContent() {
  const data = useStaticQuery(graphql`
    query {
      intro: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(aboutMe/1_intro.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
      content: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(aboutMe/2_content.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `)

  return (
    <div>
      <ResponsiveImageText
        image={
          <ResponsiveImage
            portrait={<Micki className={`stretchWide`} />}
            landscape={<Micki />}
            breakpoint={config.layoutBreakpointDesktop}
          />
        }
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data.intro.edges[0].node.html,
          }}
        />
      </ResponsiveImageText>
      <div
        dangerouslySetInnerHTML={{
          __html: data.content.edges[0].node.html,
        }}
      />
    </div>
  )
}
