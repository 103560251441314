import React from "react"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import AboutMeContent from "../components/aboutMe/aboutMeContent"
import ContactUs from "../components/common/contactUs"

const title = "Über mich"

const AboutMePage = () => (
  <Layout title={title} >
    <SEO title={title} pathname="/uebermich" />
    <AboutMeContent />
    <ContactUs />
  </Layout>
)

export default AboutMePage
